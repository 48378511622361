import React, { Component } from 'react';
import { Row, Col, Container } from "react-bootstrap"
import { checkCredentialsAPI, validIds, saveToDbAPI, saveUserEducatorCounter } from '../../common/API'
import { toast } from 'react-toastify';

import Cookies from 'universal-cookie';
import './Teacher.scss';
import tutorimg1 from '../../assets/images/tutorimg1.png';
import tutorimg2 from '../../assets/images/graph.png';
import tutorimg3 from '../../assets/images/autoGrading.jpeg';

import { APP_URL, DESKTOP, WEBSITE_URL, detectDevice, getIsPrivate } from '../../common/Functions';
import { withRouter } from 'react-router-dom';
import { PopupButton } from "react-calendly";
import { ClientJS } from 'clientjs';


let scrollPosition = 0
class Teacher extends Component {
    state = {
        email: '',
        password: '',
        validIds: [],
        showPassword: false,
        shoowearlyaccess: true,
        showemaildiv: false,
        buttontext: "Request Demo",
        isPrivate: false,
        fingerprint: null
    }

    componentWillMount() {
        this.setState({ isPrivate: getIsPrivate() })
    }

    componentDidMount() {
        this.fetchIds()
        window.addEventListener("scroll", this.handleScroll);

        const client = new ClientJS();
        const fingerprint = client.getFingerprint();
        this.setState({ fingerprint: fingerprint })
        this.saveActionforEducator('', fingerprint)
    }

    saveActionforEducator = (actionName, fingerprint) => {
        let data = {
            'user_fingerprint': fingerprint,
            'actionname': actionName,
            'scroll_position': scrollPosition
        }

        saveUserEducatorCounter(data).then(res => {
            if (res.status === 200) {
                /// Finger print details saved
            }
        })
    }

    handleScroll = (event) => {
        const scrollTop = window.scrollY; // Pixels scrolled vertically
        const windowHeight = window.innerHeight; // Height of the viewport
        const documentHeight = document.documentElement.scrollHeight; // Total height of the document
        let pos = 0
        // Calculate the percentage scrolled
        const totalScrollable = documentHeight - windowHeight;
        const percentage = (scrollTop / totalScrollable) * 100;
        scrollPosition = Math.round(Math.min(percentage, 100), 2)

        setTimeout(() => {
            console.log('scroll', scrollPosition); // Ensure it doesn't exceed 100%
        }, 2000);
    }

    handleEmailChange = (event) => {
        let email = event.target.value.trim()
        this.setState({ email: email });
        if (this.state.validIds.includes(email.toLowerCase())) {
            this.setState({ showPassword: true, buttontext: "Login" });
        } else {
            this.setState({ showPassword: false });
        }
    }

    isValidEmail = (email) => {
        return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    }

    getEarlyAccessHandler = (e) => {
        e.preventDefault()
        if (this.state.password.length > 0) {
            let data = { 'email': this.state.email.trim().toLowerCase(), password: this.state.password }

            checkCredentialsAPI(data).then(res => {
                console.log(res)
                if (res.status === 200) {
                    if (res.data['found'] === true) {
                        this.addValidationCookie(this.state.email.trim().toLowerCase(),
                            res.data.userId,
                            res.data.role,
                            res.data.name,
                            res.data.status,
                            res.data.tryThisPinsEnabled,
                            res.data.QATestFlag,
                            this.convertDurationToSeonds(res.data.timeLimit),
                            res.data.institute_id,
                            res.data.user_timezone)
                        toast.success("Valid Demo Credentials!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        window.open(APP_URL, '_self')
                        this.setState({ email: '', password: '', showPassword: false });
                        this.setState({ shoowearlyaccess: true, showemaildiv: false, buttontext: "Request Access" })
                    }
                    else if (res.data['isexpired'] === true) {
                        toast.error("Your account is expired, please get in touch with support team!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.setState({ shoowearlyaccess: true, showemaildiv: false })
                    }
                    else {
                        toast.error("Please enter valid email id and password.", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.setState({ shoowearlyaccess: false, showemaildiv: true })
                    }

                } else {
                    toast.error("Server Error !", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            }).catch(err => {
                console.error(err.message)
                toast.error("Please enter valid email id and password.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
        } else {
            // save email to db
            let data = { 'email': this.state.email.trim().toLowerCase(), studenteducatior: "Educator" }

            saveToDbAPI(data).then(res => {
                toast.success("Thank you for submitting your early access request. Our team will be in touch with you shortly to provide further information and support.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                // this.setState({ email: '' });
                // this.setState({ shoowearlyaccess: true, showemaildiv: false })

                window.open(`${WEBSITE_URL}earlyaccesspriorityinfo/${res.data.data}`)

            }).catch(err => {
                console.error(err.message)
                toast.warning("We already have your email in our system, someone from our team contact you soon.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                this.setState({ email: '' });
            })
        }
    }

    addValidationCookie = (email, userId, role, name, status, tryThisPinsEnabled, QATestFlag, timeLimit, institute_id, usertimezone) => {
        const cookies = new Cookies();
        cookies.set('isValid', 'yes', { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('email', email, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('userId', userId, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('name', name, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('role', role, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('status', status, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('tryThisPinsEnabled', tryThisPinsEnabled, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('QATestFlag', QATestFlag, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('institute_id', institute_id, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('usertimezone', usertimezone, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        console.log('cookies are ', cookies.get('isValid'), window.location.hostname);
    }

    fetchIds = () => {

        validIds().then(res => {
            this.setState({ validIds: res.data.ids.map(item => atob(item)) });
        }).catch(err => {
            console.error(err.message)
            toast.error("Error fetching ids!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })

    }

    hasValidValues = () => {
        return this.state.email.trim().length > 0
            && this.isValidEmail(this.state.email.trim())
    }

    convertDurationToSeonds = (duration) => {
        const a = duration.split(':');
        return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    }
    showemailhandler = () => {
        this.saveActionforEducator('request_demo_count', this.state.fingerprint)
        this.setState({ shoowearlyaccess: false, showemaildiv: true })
    }

    navigateToSignUp = () => {
        this.saveActionforEducator('signup_teacher_count', this.state.fingerprint)
        const { history } = this.props;
        history.push('/signup?c3VtbWl0PXRydWU=');
    }

    render() {
        return (
            this.state.isPrivate ?

                <div className='fwidth'>
                    <div className="banner-pt container-fluid bg-light-blue2" style={{ paddingBottom: '20px' }}>
                        <Container className="">
                            <Row>
                                <Col xs={12} md={12}>
                                    <div className='tutorBanner'>
                                        <h3>Revolutionizing Homework and Assessment for Physics Educators</h3>
                                        <h5>Developed by Physics Faculty, Powered by Advanced AI</h5>
                                    </div>
                                    <div className="input-group p-0 pt-3 text-center">
                                        <form className="d-flex justify-content-center">
                                            {
                                                this.state.shoowearlyaccess ? (
                                                    <button
                                                        onClick={this.showemailhandler}
                                                        className="btnMain"
                                                    >Request a Demo</button>

                                                ) : null
                                            }
                                            <button
                                                onClick={this.navigateToSignUp}
                                                title='For Free Access to Your Students'
                                                className="btnMain" style={{ marginLeft: '10px' }}
                                            >Sign up for Teachers</button>
                                            {
                                                this.state.showemaildiv ? (

                                                    <>
                                                        <div class="form-group mgbtmzero mright15 mrightzero">
                                                            <input
                                                                autoFocus
                                                                type="email"
                                                                className="form-control mr-2 inp mobinpst "
                                                                value={this.state.email}
                                                                onChange={this.handleEmailChange}
                                                                placeholder="Enter your email address" />
                                                        </div>
                                                        {this.state.showPassword ? <div class="form-group mgbtmzero mright15 mrightzero">
                                                            <input
                                                                type="password"
                                                                className="form-control mr-2 inp mobinpst "
                                                                value={this.state.password}
                                                                onChange={(e) => this.setState({ password: e.target.value })}
                                                                placeholder="Enter your password" />
                                                        </div> : null}
                                                        <button
                                                            onClick={this.getEarlyAccessHandler}
                                                            disabled={!this.hasValidValues()}
                                                            className="btn btn-primary formbtnget"
                                                            type="submit">{this.state.buttontext}</button>
                                                    </>
                                                ) : null
                                            }
                                        </form>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} >
                                    <div className='bulletsContainer'>
                                        <ul>
                                            <li>The only AI trained on deep research in “expert tutoring” and “active learning” for STEM​</li>
                                            <li>The only AI you can rely on for ~100% correct answers, and ~100% correct grading​​</li>
                                            <li>The only AI universities trust for step-by-step grading and feedback – personalized, detailed, immediate​​</li>
                                        </ul>
                                    </div>

                                </Col>
                                <Col xs={12} md={12}>
                                    <div style={{ backgroundColor: 'white', border: '1px solid white', borderRadius: '10px', padding: '10px' }}>
                                        <div style={{ textAlign: 'center' }}><strong>Join us at AAPT 2025!</strong></div><br />
                                        <span style={{ fontStyle: 'italic' }}>
                                            We’re offering a <strong>FREE pilot program</strong> to 10 universities/schools attending AAPT. Visit us at <strong>Booth #202</strong> to learn more. You can <strong>schedule a meeting</strong>
                                            <a onClick={() => this.saveActionforEducator('schedule_count', this.state.fingerprint)}>
                                                <PopupButton
                                                    url="https://calendly.com/pilot-aiplato/aiplato-aapt-jan-19and20-2025"
                                                    rootElement={document.getElementById("root")}
                                                    text="here"
                                                    styles={{
                                                        backgroundColor: "#fff",
                                                        color: "#0078FF",
                                                        border: "none",
                                                        fontStyle: "italic",
                                                        textDecoration: 'underline',
                                                        cursor: "pointer",
                                                        paddingLeft: '5px',
                                                        paddingRight: '5px'

                                                    }}
                                                /></a>
                                            or email us at{' '}
                                            <a onClick={() => this.saveActionforEducator('email_count', this.state.fingerprint)}>
                                                <a href="mailto:pilot@aiplato.ai" style={{ color: "#0078FF", textDecoration: "underline" }}>
                                                    pilot@aiplato.ai
                                                </a></a>
                                        </span>
                                    </div>
                                </Col>


                            </Row>
                            {/* <div className="col-12 col-md-12 col-lg-12 d-flex align-items-center">
                                <div className='fwidth'>
                                    <div className='homeBannerHead1'>
                                        <h3>Revolutionizing Homework and Assessment for Physics Educators</h3>
                                        <h5>Developed by Physics Faculty, Powered by Advanced AI</h5>
                                    </div>
                                    

                                    <div className="input-group p-0 pt-3 text-center">
                                        <form className="d-flex justify-content-center">
                                            {
                                                this.state.shoowearlyaccess ? (
                                                    <button
                                                        onClick={this.showemailhandler}
                                                        className="btnMain"
                                                    >Request a Demo</button>

                                                ) : null
                                            }
                                            <button
                                                onClick={this.navigateToSignUp}
                                                title='For Free Access to Your Students'
                                                className="btnMain" style={{ marginLeft: '10px' }}
                                            >Sign up for Teachers</button>
                                            {
                                                this.state.showemaildiv ? (

                                                    <>
                                                        <div class="form-group mgbtmzero mright15 mrightzero">
                                                            <input
                                                                autoFocus
                                                                type="email"
                                                                className="form-control mr-2 inp mobinpst "
                                                                value={this.state.email}
                                                                onChange={this.handleEmailChange}
                                                                placeholder="Enter your email address" />
                                                        </div>
                                                        {this.state.showPassword ? <div class="form-group mgbtmzero mright15 mrightzero">
                                                            <input
                                                                type="password"
                                                                className="form-control mr-2 inp mobinpst "
                                                                value={this.state.password}
                                                                onChange={(e) => this.setState({ password: e.target.value })}
                                                                placeholder="Enter your password" />
                                                        </div> : null}
                                                        <button
                                                            onClick={this.getEarlyAccessHandler}
                                                            disabled={!this.hasValidValues()}
                                                            className="btn btn-primary formbtnget"
                                                            type="submit">{this.state.buttontext}</button>
                                                    </>
                                                ) : null
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div> */}
                        </Container>
                    </div>

                    {/* <div className="bgpat prelative">
                    <Container className="homecontmpad">
                        <div className="col-12 col-md-12 col-lg-12 d-flex align-items-center">
                            <div className='fwidth'>
                                <div className='display-1 tcenter'>aiPlato</div>
                               
                                <h5><div className='tcenter mgbtm25 subHeader'>Democratizing 1-on-1 Teaching</div></h5>
                                <div className="input-group">
                                    <form className="ctflex jcenter flcplm">
                                        {
                                            this.state.shoowearlyaccess ? (
                                                <button
                                                    onClick={this.showemailhandler}
                                                    className="btn btn-primary formbtnget"
                                                >Get Early Access as an Educator</button>

                                            ) : null
                                        }
                                        {
                                            this.state.showemaildiv ? (

                                                <>
                                                    <div class="form-group mgbtmzero mright15 mrightzero">
                                                        <input
                                                        autoFocus
                                                            type="email"
                                                            className="form-control mr-2 inp mobinpst "
                                                            value={this.state.email}
                                                            onChange={this.handleEmailChange}
                                                            placeholder="Enter your email address" />
                                                    </div>
                                                    {this.state.showPassword ? <div class="form-group mgbtmzero mright15 mrightzero">
                                                        <input
                                                            type="password"
                                                            className="form-control mr-2 inp mobinpst "
                                                            value={this.state.password}
                                                            onChange={(e) => this.setState({ password: e.target.value })}
                                                            placeholder="Enter your password" />
                                                    </div> : null}
                                                    <button
                                                        onClick={this.getEarlyAccessHandler}
                                                        disabled={!this.hasValidValues()}
                                                        className="btn btn-primary formbtnget"
                                                        type="submit">{this.state.buttontext}</button>
                                                </>
                                            ) : null
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div> */}
                    {/* <div className='container-fluid bg-gray1'>
                        <Container className="secpad">
                            <Row className="">
                                <Col xs={12} md={12}>
                                    <ul>
                                        <li>The only AI trained on deep research in “expert tutoring” and “active learning” for STEM​</li>
                                        <li>The only AI you can rely on for ~100% correct answers, and ~100% correct grading​​</li>
                                        <li>The only AI top universities trust for step-by-step grading and feedback – personalized, detailed, immediate​​</li>
                                    </ul>
                                  
                                </Col>
                            </Row>
                        </Container>
                    </div> */}
                    <div className='container-fluid bg-white'>
                        <div className='container-fluid py-5'>
                            <Container>
                                <Row className="">


                                    <Col xs={12} md={6}>
                                        <img className='accImg img-fluid' alt='' src={tutorimg1}></img>
                                    </Col>
                                    <Col xs={12} md={6} className='d-flex flex-column justify-content-center' style={{marginTop: detectDevice() === DESKTOP ?'0':'15px'}}>
                                        <h2 className='bannerTitle'>Comprehensive Homework and Assessment Platform</h2>
                                        <ul className='copyText topicText'>
                                            <li>Assign auto-graded homework and exams tailored to your curriculum</li>
                                            <li>Accept handwritten solutions, or work touchpad-written (or typed) directly on the platform​</li>
                                            <li>Deliver real-time, personalized, interactive feedback to help students understand their mistakes and improve - trace root causes, reinforce knowledge gaps, achieve mastery​</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className='container-fluid py-5 '>
                            <Container>
                                {detectDevice() === DESKTOP ?
                                    <Row>

                                        <Col xs={12} md={6} className='d-flex flex-column justify-content-center'>
                                            <h2 className='bannerTitle'>Efficient Auto-Grading System</h2>
                                            <ul className='copyText topicText'>
                                                <li>Intelligent grading of multi-step problems, with partial credit</li>
                                                <li>Save hours on grading assignments and exams while ensuring precision and consistency</li>
                                                <li>Ensure fairness and transparency in student evaluations</li>
                                            </ul>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <img className='accImg img-fluid' alt='' src={tutorimg3}></img>
                                        </Col>
                                    </Row> :
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <img className='accImg img-fluid' alt='' src={tutorimg3}></img>
                                        </Col>
                                        <Col xs={12} md={6} className='d-flex flex-column justify-content-center' style={{marginTop: detectDevice() === DESKTOP ?'0':'15px'}}>
                                            <h2 className='bannerTitle'>Efficient Auto-Grading System</h2>
                                            <ul className='copyText topicText'>
                                                <li>Intelligent grading of multi-step problems, with partial credit</li>
                                                <li>Save hours on grading assignments and exams while ensuring precision and consistency</li>
                                                <li>Ensure fairness and transparency in student evaluations</li>
                                            </ul>
                                        </Col>

                                    </Row>
                                }

                            </Container>
                        </div>
                        <div className='container-fluid py-5'>
                            <Container>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <img className='accImg img-fluid' alt='' src={tutorimg2}></img>
                                    </Col>
                                    <Col xs={12} md={6} className='d-flex flex-column justify-content-center' style={{marginTop: detectDevice() === DESKTOP ?'0':'15px'}}>
                                        <h2 className='bannerTitle'>Adaptive Learning Insights for Educators</h2>
                                        <ul className='copyText topicText'>
                                            <li>Monitor individual and class performance with proficiency maps, 800-concept-detailed</li>
                                            <li>Identify common errors and areas of difficulty to refine your teaching strategies</li>
                                            <li>Leverage actionable data to improve student outcomes</li>
                                        </ul>
                                    </Col>

                                </Row>

                            </Container>
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <Container>
                                <Row>
                                    <form className="">
                                        {
                                            this.state.shoowearlyaccess ? (
                                                <button
                                                    onClick={this.showemailhandler}
                                                    className="btnMain"
                                                >Request a Demo</button>

                                            ) : null
                                        }
                                        {
                                            this.state.showemaildiv ? (

                                                <>
                                                    <div class="form-group mgbtmzero mright15 mrightzero">
                                                        <input
                                                            autoFocus
                                                            type="email"
                                                            className="form-control mr-2 inp mobinpst "
                                                            value={this.state.email}
                                                            onChange={this.handleEmailChange}
                                                            placeholder="Enter your email address" />
                                                    </div>
                                                    {this.state.showPassword ? <div class="form-group mgbtmzero mright15 mrightzero">
                                                        <input
                                                            type="password"
                                                            className="form-control mr-2 inp mobinpst "
                                                            value={this.state.password}
                                                            onChange={(e) => this.setState({ password: e.target.value })}
                                                            placeholder="Enter your password" />
                                                    </div> : null}
                                                    <button
                                                        onClick={this.getEarlyAccessHandler}
                                                        disabled={!this.hasValidValues()}
                                                        className="btn btn-primary formbtnget"
                                                        type="submit">{this.state.buttontext}</button>
                                                </>
                                            ) : null
                                        }
                                    </form>
                                </Row>
                            </Container>
                        </div>
                        {/* <Container className="secpad">
                            <Row>
                                
                            </Row>
                        </Container> */}
                    </div>
                    <div className='container-fluid bg-light-blue2'>
                        <Container className="secpad">
                            <Row className="">

                                <Col xs={12} md={12}>
                                    <p className='copytext'>With aiPlato, education goes beyond delivering content—it's about creating a seamless learning ecosystem. Empower your students with AI-driven support and take advantage of our sophisticated autograding to free your schedule and focus on what you do best—teaching. Embrace the future with aiPlato, your partner in educational excellence.</p>
                                </Col>
                                <Col xs={12} md={12}>
                                    <div style={{ marginTop: '5px' }}>aiPlato platform adheres to FERPA guidelines.</div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* <div>
                        <PopupButton
                            url="https://calendly.com/sunil-aiplato/test"
                            rootElement={document.getElementById("root")}
                            text="Schedule a Meeting"
                            styles={{
                                backgroundColor: "#0078FF",
                                color: "white",
                                padding: "10px 20px",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                            }}
                        />
                        <PopupButton
                            url="https://calendly.com/sunil-aiplato/webinar"
                            rootElement={document.getElementById("root")}
                            text="Schedule a Meeting"
                            styles={{
                                backgroundColor: "#0078FF",
                                color: "white",
                                padding: "10px 20px",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                            }}
                        />
                    </div> */}
                    {/* <Container className=""> }
                    <Row className="">
                        <Col xs={12} md={12}>
                            <hr />
                        </Col>
                    </Row>
                </Container>
                <Container className="secpad">
                    <Row className="teacherContentImg">
                        <Col xs={12} md={6}>
                            <img src={img2} />

                        </Col>
                        <Col xs={12} md={6}>
                            <h2 className="oftitle mgbtm25">Interactive learning <br />experience by aiPlato</h2>
                            <p className="">The time has come to provide every student with an interactive learning experience equivalent to one-on-one teaching – personalized to their cognitive processing in real-time. The time has come for online education to help each student overcome the obstacles in acing every problem - watching every error, diagnosing the root cause, and reinforcing knowledge-gaps, like a one-on-one teacher.  21st century education must stimulate curiosity. We must turn education from a chore that students dread into a game-like experience that they enjoy.  And yes, human tutoring must be accessible to every student, assisted by AI.</p>
                        </Col>
                    </Row>
                </Container>
                <Container className="">
                    <Row className="">
                        <Col xs={12} md={12}>
                            <hr />
                        </Col>
                    </Row>
                </Container>
                <Container className="secpad">
                    <Row className="teacherContentImg">
                        <Col xs={12} md={6}>
                            <h2 className="oftitle mgbtm25">Interactive learning <br />experience by aiPlato</h2>
                            <p className="">The time has come to provide every student with an interactive learning experience equivalent to one-on-one teaching – personalized to their cognitive processing in real-time. The time has come for online education to help each student overcome the obstacles in acing every problem - watching every error, diagnosing the root cause, and reinforcing knowledge-gaps, like a one-on-one teacher.  21st century education must stimulate curiosity. We must turn education from a chore that students dread into a game-like experience that they enjoy.  And yes, human tutoring must be accessible to every student, assisted by AI.</p>
                        </Col>
                        <Col xs={12} md={6}>
                            <img src={img3} />
                        </Col>
                    </Row>
                </Container> */}
                </div>

                :

                null
        )
    }
}
export default withRouter(Teacher);