import React, { Component } from 'react';
import { Row, Col, Container } from "react-bootstrap"
import './team.scss';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';


import company1logo from '../../assets/images/c1.png';
import company2logo from '../../assets/images/c21.png'
import c22 from '../../assets/images/c22.png'
import c23 from '../../assets/images/c23.png'
import person1logo from '../../assets/images/p1.png';
import person2logo from '../../assets/images/p2.png';


import marklogo1 from '../../assets/images/marklogo1.png';
import pallavilogo1 from '../../assets/images/pallavilogo1.png';
import marklogo2 from '../../assets/images/marklogo2.png';
import marklogo3 from '../../assets/images/marklogo3.png';
import pallavilogo2 from '../../assets/images/pallavilogo2.png';
import mark from '../../assets/images/mark.png';
import pallavi from '../../assets/images/pallavi.png';


import p3 from '../../assets/images/p3.png';
import p4 from '../../assets/images/p4.png';
import p5 from '../../assets/images/p5.png';
import p6 from '../../assets/images/p6.png';
import p7 from '../../assets/images/p7.png';
import p8 from '../../assets/images/p8.png';
import p9 from '../../assets/images/p9.png';

import c3 from '../../assets/images/c3.png'
import c4 from '../../assets/images/c4.png'
// import c5 from '../../assets/images/c5.png'
import c6 from '../../assets/images/c6.png'
import c7 from '../../assets/images/c7.png'
import c8 from '../../assets/images/c8.png'
import Cookies from 'universal-cookie';
import { getIsPrivate } from '../../common/Functions';


class Team extends Component {

    state = {
        isPrivate: false,
    }

    componentWillMount() {
        this.setState({ isPrivate: getIsPrivate() })
    }

    render() {
        window.scroll(0, 0)

        return (
            this.state.isPrivate ?
                <div className='fwidth'>
                    <div className="banner-pt container-fluid bg-gray1">
                        <Container className="gradientBannerBg ">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className='homeBannerHead1'>
                                    <h1>Meet the Team</h1>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="container-fluid bg-gray1">
                        <Container className="secpad">
                            <Row className="">
                                <Col xs={12} md={12}>
                                    <div className="copyText">Our exceptional team is comprised of distinguished educators from prestigious institutions, AI experts from renowned Silicon Valley giants and talented, entrepreneurial-minded engineers. Get to know some of the passionate individuals who are dedicated to empowering students and shaping the future of education. Together, we bring a wealth of knowledge and experience from leading institutions and corporations with  best AI-powered 1-on-1 tutoring experience for our students.
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="secpad">
                        <Row className='mt-3'>
                            <Col xs={12} md={12} >
                                <div  className='subheadText pb-3'>
                                    Leadership
                                </div>
                            </Col>
                        </Row>
                        
                        <Row className='teamsCards' >
                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={person1logo}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Nimish Shah
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                                Founder & CEO <br/>  Serial entrepreneur, founded first company 25 years ago, funded by Sequoia & Accel, acquired by Cisco.
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={company1logo}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>

                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={p7}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Dr. Louis Deslauriers
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                                Chief of Learning Science, Co-Founder<br/>
                                                Harvard physics faculty, authored influential papers on active learning and its implications for effective tutoring.
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={c7}
                                            />

                                        </Box>
                                    </Box>

                                </Card>
                            </Col>


                        </Row>
                        <Row >
                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "200px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={person2logo}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Dr. Nimish Radia
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                            Chief of AI/ML, Co-Founder <br/> Led AI/ML teams at Google, Ericsson. Led 80+ AI/ML engineer teams.Led industry-shaping AI innovations in Telecom/Networking , other sectors.
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={company2logo}


                                            />
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70, paddingLeft: "20px" }}
                                                image={c22}


                                            />
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70, paddingLeft: "20px" }}
                                                image={c23}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>

                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "200px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={p9}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Khyati Shah
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                            Sr. Director of Engineering, Co-Founder<br/>Led 25 analytics/AI/SW Engineers. Earned reputation as the  center for rapid-prototyping and MVP for big-data/AI company.
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={c4}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>


                        </Row>
                        <Row >
                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={mark}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                            Mark Gopez
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                            Head of Growth <br/> Drove 800% growth in 12 months, while reducing CAC to 1/6th.
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                        <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={marklogo1}


                                            />
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 93, paddingLeft: "0px", height: "20px"}}
                                                image={marklogo2}


                                            />
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 130, paddingLeft: "10px", height:"20px"}}
                                                image={marklogo3}


                                            />
                                            
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>

                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={pallavi}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                            Pallavi Bagchi
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                            UX Designer <br/>U Washington MS, Deloitte
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                        <CardMedia
                                                component="img"
                                                sx={{ width: 50 }}
                                                image={pallavilogo1}


                                            />
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 120, paddingLeft: "10px" }}
                                                image={pallavilogo2}


                                            />
                                            
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>


                        </Row>
                        <Row>
                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "170px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={p5}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Sunil Ratada
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                                Principal Engineer
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={c4}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>
                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "170px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={p4}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Bhargav Vyas
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                                Director Engineering
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={c4}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>
                        </Row>
                        <Row className='mt-2'>


                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={p3}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Dr. Ranjeet Tate
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                            Lead Data Scientist, Physics.
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={c3}


                                            />

                                            <CardMedia
                                                component="img"
                                                sx={{ width: 45, paddingLeft: '10px' }}
                                                image={c8}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={12} md={12}>
                                <div  className='subheadText pb-3'>
                                    Advisors
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={p6}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Dr. Vijay Erramilli
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                                AI/ML Advisor. Principal Data Scientist, Einstein Team.
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={c6}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>
                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={p8}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Natasha Prasad
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                                Growth & Strategy Advisor.
                                                CXO – Cleo, ClassPass, Atlassian. Harvard MBA.
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={c7}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>

                        </Row>                        

                    </Container>

                </div>
                :
                <div className='fwidth'>
                    <div className="homecontmpad container-fluid">
                        <Container className="">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className='homeBannerHead text-white'>Meet the Team 
                                    {/* <h1>Democratizing 1-on-1 Teaching</h1> */}
                                </div>
                            </div>
                        </Container>
                    </div>
                    <Container className="secpad">
                        <Row className="">
                            <Col xs={12} md={12}>
                                <div className="copyText">Our exceptional team is comprised of distinguished educators from prestigious institutions such as Harvard and Stanford, AI experts from renowned Silicon Valley giants and talented, entrepreneurial-minded engineers. Get to know some of the passionate individuals who are dedicated to empowering students and shaping the future of education. Together, we bring a wealth of knowledge and experience from leading institutions and corporations with  best AI-powered 1-on-1 tutoring experience for our students.
                                </div>
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col xs={12} md={12} >
                                <Typography component="div" variant="h4" className='bannerTitle2'>
                                    Leadership
                                </Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} className='my-2'>
                                <hr />
                            </Col>
                        </Row>
                        <Row >
                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={person1logo}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', fontFamily:'proxima-nova, sans-serif'  }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Nimish Shah
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                                Founder.  Serial entrepreneur, founded first company 25 years ago, funded by Sequoia & Accel, acquired by Cisco.
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={company1logo}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>

                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={p7}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', fontFamily:'proxima-nova, sans-serif'  }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Dr. Louis Deslauriers
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                                Chief of Learning Science.
                                                Harvard physics faculty, authored influential papers on active learning and its implications for effective tutoring.
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={c7}
                                            />

                                        </Box>
                                    </Box>

                                </Card>
                            </Col>


                        </Row>
                        <Row >
                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={person2logo}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', fontFamily:'proxima-nova, sans-serif'  }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Dr. Nimish Radia
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                                VP AI/ML. Led 80-strong AI/ML team.
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={company2logo}


                                            />
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70, paddingLeft: "20px" }}
                                                image={c22}


                                            />
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70, paddingLeft: "20px" }}
                                                image={c23}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>

                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={p3}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', fontFamily:'proxima-nova, sans-serif'  }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Dr. Ranjeet Tate
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                                Lead Data Scientist, Physics.
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={c3}


                                            />

                                            <CardMedia
                                                component="img"
                                                sx={{ width: 45, paddingLeft: '10px' }}
                                                image={c8}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>


                        </Row>
                        <Row>
                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={p5}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', fontFamily:'proxima-nova, sans-serif'  }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Sunil Ratada
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                                Principal Engineer
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={c4}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>
                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={p4}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', fontFamily:'proxima-nova, sans-serif'  }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Bhargav Vyas
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                                Director Engineering
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={c4}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={12} md={12}>
                                <Typography component="div" variant="h4" className='bannerTitle2'>
                                    Advisors
                                </Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <hr />
                            </Col>
                        </Row>



                        <Row >
                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={p6}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', fontFamily:'proxima-nova, sans-serif' }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Dr. Vijay Erramilli
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                                AI/ML Advisor. Principal Data Scientist, Einstein Team.
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={c6}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>
                            <Col xs={12} md={6} className='my-2'>
                                <Card sx={{ display: 'flex', height: "180px" }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 150, padding: "10px", marginBottom: "30px" }}
                                        image={p8}
                                        rounded

                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', fontFamily:'proxima-nova, sans-serif'  }}>
                                        <CardContent
                                        //sx={{ flex: '1 0 auto' }}
                                        >
                                            <Typography component="div" sx={{ fontSize: "1.10rem" }}>
                                                Natasha Prasad
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                                Growth & Strategy Advisor.
                                                CXO – Cleo, ClassPass, Atlassian. Harvard MBA.
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 70 }}
                                                image={c7}


                                            />
                                        </Box>
                                    </Box>

                                </Card>
                            </Col>

                        </Row>                

                    </Container>

                </div>
        )
    }
}

export default Team;
