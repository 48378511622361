import { Button } from 'bootstrap';
import { Row, Col, Container } from "react-bootstrap"
import React, { Component } from 'react';
import Hyperlink from '../shared/Hyperlink/Hyperlink'
import './navbar.scss'
import logoImg from '../../assets/images/logo_aiPlato-h.svg'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ButtonLogin from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import { checkCredentialsAPI } from '../../common/API'
import { toast } from 'react-toastify';
import { APP_URL, setCookies, showFullApp } from '../../common/Functions';
import '../Login/Login.scss'
import Login from '../Login/Login';


class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavExpanded: false,
      isOpen: false,
      loginEmail: '',
      validLoginEmail: false,
      password: '',
      redirectToQue: '',
      sessionId: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isExpanded !== prevProps.isExpanded) {
      this.setState({ isNavExpanded: this.props.isExpanded });
    }
  }
  toggleNav = () => {
    this.setState(prevState => ({
      isNavExpanded: !prevState.isNavExpanded
    }));
  }

  onMenuItemSelection = () => {
    this.setState({ isNavExpanded: false })
  }

  handleOpen = (event) => {
    this.setState({ isOpen: true })
  };

  handleClose = () => {
    this.setState({ isOpen: false })
  };


  render() {
    const { isNavExpanded } = this.state;

    return (

      <div className='container-fluid Nav-Container'>
        <Modal style={{
          position: 'absolute',
          top: '-18%',
          left: '66%',
          justify: 'center'
        }}
          open={this.state.isOpen}
          onClose={this.handleClose}
          aria-describedby="modal-modal-description"
        >
          <Login showHeader={false} />

        </Modal>

        <Container >
          <Row>
            <Col className='pt-1'>
              <a alt="aiPlato" href="/" className="navbar-brand"><img src={logoImg} alt="aiPlato"></img></a>
            </Col>
            <Col>
              {document.location.href.indexOf("/feedback") === -1 ?
                <nav className="navbar navbar-expand-lg navbar-light px-0">
                  <button onClick={this.toggleNav} className={isNavExpanded ? 'navbar-toggler' : 'navbar-toggler collapsed'}
                    data-toggle={!isNavExpanded ? "collapse" : "collapse navbar-collapse"} data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
                    aria-expanded="false" aria-label="Toggle navigation"
                    type="button">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className={!isNavExpanded ? "collapse navbar-collapse" : "collapse"} id="navbarNavAltMarkup">
                    <div className="navbar-nav ">
                      <Hyperlink onLinkClick={this.onMenuItemSelection} type="Link_Primary" className="nav-item" href='/'>Home</Hyperlink>
                      {/* <Hyperlink type="Link_Primary" className="nav-item" href="student">Student</Hyperlink> */}
                      <Hyperlink onLinkClick={this.onMenuItemSelection} type="Link_Primary" className="nav-item" href="/educator">For Educators</Hyperlink>
                      <Hyperlink onLinkClick={this.onMenuItemSelection} type="Link_Primary" className="nav-item" href="/team">About Us</Hyperlink>
                      <Hyperlink onLinkClick={this.onMenuItemSelection} type="Link_Primary" className="nav-item" href="/careers">Careers</Hyperlink>
                      <Hyperlink onLinkClick={this.onMenuItemSelection} type="Link_Primary" className="nav-item" href="/contact">Contact Us</Hyperlink>
                      {/*<div className="earlyaccessbtn">Get Early Access</div>*/}
                    </div>
                    <div className='loginSignup'>
                      {showFullApp() ?
                        <Hyperlink onLinkClick={this.handleOpen} type="Link_Primary" className="nav-item " href="">Login</Hyperlink>
                        :
                        <Hyperlink onLinkClick={this.onMenuItemSelection} type="Link_Primary" className="nav-item " href="/login">Login</Hyperlink>
                      }

                      {/* <Hyperlink onLinkClick={this.onMenuItemSelection} type="Link_Primary" className="nav-item " href="/signup">Sign Up</Hyperlink>*/}
                    </div>
                  </div>
                </nav>
                : null}
            </Col>
          </Row>
        </Container>

      </div>
    );
  };
}

export default Navigation;
