import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { checkCredentialsAPI, passwordresetsendemail } from '../../common/API'
import { toast } from 'react-toastify';
import { APP_URL, WEBSITE_URL, getIsPrivate, setCookies, showFullApp } from '../../common/Functions';
import { isNil } from 'lodash';
import './Login.scss';
import CircularProgress from '@mui/material/CircularProgress';
// import CryptoJS from 'crypto-js';

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    height: 'fit-content'
};
const stylefp = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    height: 'fit-content'
};

const style1 = {
    my: 1,
    mx: 'auto',
    width: ['100%', 500]
}

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loginEmail: '',
            validLoginEmail: false,
            password: '',
            redirectToQue: '',
            sessionId: '',
            isPrivate: false,
            showHeader: true,
            isForgotPasswordClicked: false,
            loading: false,
        }
    }

    componentWillMount() {
        this.setState({ isPrivate: getIsPrivate() })
    }

    componentDidMount() {
        this.getURLParams()
        if (this.props.showHeader !== undefined) {
            this.setState({ showHeader: this.props.showHeader })
        }
    }

    getURLParams = () => {
        const queryParameters = new URLSearchParams(window.location.search.substring(1));
        if (!isNil(queryParameters) && queryParameters.size > 0) {
            this.setState({ sessionId: queryParameters.get("sessionId"), redirectToQue: queryParameters.get('fromQue').toString() });
        }

    }
    validateEmail = (input) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(input);
    }

    handleEmailChange = (event) => {
        const newEmail = event.target.value;
        this.setState({ loginEmail: newEmail });
        this.setState({ validLoginEmail: this.validateEmail(newEmail) });
    }
    // encryptPassword = (password) => {
    //     return CryptoJS.AES.encrypt(password, "sDJwT4ZG2NBj4wOa3rR3A76jtzZqZ9SkZ8uQ4V2s5Go=").toString();
    // };

    getEarlyAccessHandler = (e) => {
        e.preventDefault()
        if (this.state.password.length > 0) {
            let data = { 'email': this.state.loginEmail.trim().toLowerCase(), password: (this.state.password.trim()), signupmethod: "aiPlato" }

            //   window.location.href = "/createclass"
            checkCredentialsAPI(data).then(res => {
                if (res.status === 200) {
                    if (res.data['found'] === true && res.data.status === "Active") {
                        this.addValidationCookie(res.data.email.trim().toLowerCase(),
                            res.data.userId,
                            res.data.role,
                            res.data.name,
                            res.data.status,
                            res.data.tryThisPinsEnabled,
                            res.data.QATestFlag,
                            this.convertDurationToSeonds(res.data.timeLimit),
                            res.data.institute_id,
                            res.data.user_timezone,
                            res.data.isDemoUser,
                            res.data.isPtTeacher,
                            res.data.menuselecteditem,
                            res.data.isAdminUser,
                            res.data.userPlan
                        )
                        debugger;

                        if (this.state.redirectToQue === 'true') {
                            window.location.href = `/question/?sessionId=${this.state.sessionId}`
                        }
                        else if (Number(res.data.institute_id) !== 1) {
                            window.open(APP_URL + "assignments", '_self')

                        } else {
                            if (showFullApp()) {
                                window.open(APP_URL + "curriculum-problems", '_self')
                            } else {
                                window.open(APP_URL, '_self')
                            }

                            this.setState({ email: '', password: '', showPassword: false });
                            this.setState({ shoowearlyaccess: true, showemaildiv: false, showdropdown: true, studenttutorvalue: "", buttontext: "Request Access" })
                        }

                    }
                    else if (res.data['isexpired'] === true) {
                        toast.error("Your account is expired, please get in touch with support team!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.setState({ shoowearlyaccess: true, showemaildiv: false })
                    }
                    else {
                        toast.error("Please enter valid email id and password.", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.setState({ shoowearlyaccess: false, showemaildiv: true })
                    }
                } else {
                    toast.error("Server Error !", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            }).catch(err => {
                console.error(err.message)
                toast.error("Please enter valid email id and password.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
        }

    }
    addValidationCookie = (email, userId, role, name, status, tryThisPinsEnabled, QATestFlag, timeLimit, institute_id, usertimezone, isDemoUserFlagged, isPtTeacher, menuselecteditem, isAdminUser, userPlan) => {
        setCookies(email, userId, role, name, status, tryThisPinsEnabled, QATestFlag, timeLimit, institute_id, usertimezone, "", false, isDemoUserFlagged, isPtTeacher, menuselecteditem, isAdminUser, userPlan)
    }

    convertDurationToSeonds = (duration) => {
        const a = duration.split(':');
        return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    }

    handleforgotpassword = () => {
        this.setState({ isForgotPasswordClicked: true, loginEmail: '', password: '' })
    }
    handlecancelforgotpassword = () => {
        this.setState({ isForgotPasswordClicked: false, loginEmail: '', password: '' })
    }
    handleresetPassword = () => {
        this.setState({ loading: true })
        const resparams = {
            "useremail": this.state.loginEmail
        }
        passwordresetsendemail(resparams).then(res => {
            if (res.status === 200) {
                this.setState({ loading: false })
                if (res.data === "0") {
                    toast.error("Please check entered email address, it does not exist in the system.", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
                else {
                    toast.info("Your password has been successfully reset, and a new password has been sent to your registered email address.", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    this.setState({ isForgotPasswordClicked: false, loginEmail: '', password: '' })
                }
            }
        }).catch(err => {
            console.error(err.message)
            this.setState({ loading: false })
        })


    }

    render() {

        return (

            <>
                {
                    this.state.isPrivate ?

                        <div className='fwidth'>
                            {this.state.showHeader ?
                                <div className="banner-pt container-fluid bg-gray1 pb-3">
                                    <Container className="gradientBannerBg">
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <div className='homeBannerHead1'>
                                                <h1>STEM AI Tutor</h1> <h3>Loved by Students, Developed by Educators</h3>
                                            </div>
                                        </div>
                                    </Container>
                                </div>
                                : null
                            }
                            <div className="container-fluid px-0">
                                <Container className='pt-2 pt-md-5'>
                                    {
                                        !this.state.isForgotPasswordClicked ? (<Box component="form" onSubmit={this.getEarlyAccessHandler} sx={this.state.showHeader ? style1 : style} className='whiteBG '>
                                            {this.state.showHeader ?
                                                <Typography component="h1" variant="h5">
                                                    Login
                                                </Typography>
                                                : null
                                            }
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                label="Email Address/User Id"
                                                name="email"
                                                autoComplete="email"
                                                autoFocus
                                                value={this.state.loginEmail}
                                                onChange={this.handleEmailChange}
                                            // error={!this.state.validLoginEmail}
                                            // helperText={!this.state.validLoginEmail ? 'Invalid email format' : ''}
                                            />
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="password"
                                                label="Password"
                                                type="password"
                                                id="password"
                                                value={this.state.password}
                                                onChange={(e) => this.setState({ password: e.target.value })}
                                                autoComplete="current-password"
                                            />
                                            <Button variant="text" sx={{ textTransform: "capitalize", cursor: "pointer" }} onClick={this.handleforgotpassword}>Forgot Password</Button>

                                            {this.state.loginEmail !== '' ? <Button className='btnmain' type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> Sign In </Button>
                                                : <Button className='btnmain' style={{ cursor: ' not-allowed' }} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> Sign In </Button>}

                                            <div style={{ textAlign: 'center', marginBottom: '10px' }}>OR</div>
                                            {/* <GoogleLoginPage></GoogleLoginPage> */}
                                            <Grid container>
                                                {/* <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?bt
                            </Link>
                        </Grid>  */}
                                                {this.state.showHeader ?
                                                    <Grid item style={{ marginTop: '10px' }}>
                                                        <Link target="_self" to="/signUpPlans" variant="body2">
                                                            {"Don't have an account? Sign Up"}
                                                        </Link>
                                                    </Grid>
                                                    :
                                                    <Grid item style={{ marginTop: '10px' }}>
                                                        <a href={WEBSITE_URL + "signUpPlans"}>
                                                            {"Don't have an account? Sign Up"}
                                                        </a>
                                                    </Grid>
                                                }
                                            </Grid>

                                        </Box>) : (
                                            <Box component="form" sx={stylefp} className='whiteBG '>

                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id="email"
                                                    label="Email Address"
                                                    name="email"
                                                    autoComplete="email"
                                                    autoFocus
                                                    value={this.state.loginEmail}
                                                    onChange={this.handleEmailChange}
                                                />
                                                <Button variant="contained" sx={{ textTransform: "capitalize", mt: 2 }} onClick={this.handleresetPassword}> Reset Password </Button>
                                                <Button variant="outlined" sx={{ textTransform: "capitalize", marginLeft: "6px", mt: 2 }} onClick={this.handlecancelforgotpassword}> Cancel </Button>
                                            </Box>)
                                    }

                                </Container>
                            </div>
                        </div>

                        :
                        null
                }

                {this.state.loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '30%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}

            </>
        )
    }
}

export default withRouter(Login);